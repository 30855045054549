/********** Template CSS **********/
:root {
	--primary: #00b87b;
	--secondary: #314355;
	--light: #f2f2f2;
	--dark: #2c3e50;
}

@media (max-width: 991.98px) {
	.sticky-lg-top.vh-100 {
		height: 100% !important;
	}
}

.fw-semi-bold {
	font-weight: 600;
}

.fw-medium {
	font-weight: 500;
}

.btn-square {
	width: 40px;
	height: 40px;
}

.btn-sm-square {
	width: 30px;
	height: 30px;
}

.btn-lg-square {
	width: 50px;
	height: 50px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn.btn-primary {
	color: #ffffff;
}

.typed-cursor {
	font-size: 25px;
	color: var(--light);
}

.back-to-top {
	position: fixed;
	display: none;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 1;
	font-size: 30px;
	right: 30px;
	bottom: 30px;
	transition: background 0.5s;
	z-index: 11;
}

.back-to-top i {
	color: var(--primary);
}

.back-to-top i:hover {
	color: var(--dark);
}

.back-to-top {
	-webkit-animation: action 1s infinite alternate;
	animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-15px);
	}
}

@keyframes action {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-15px);
	}
}

.title {
	position: relative;
}

.title::before {
	position: absolute;
	content: '';
	width: 10px;
	height: 10px;
	bottom: -4px;
	left: 0;
	border: 2px solid var(--light);
	border-radius: 10px;
}

.title::after {
	position: absolute;
	content: '';
	width: 50px;
	height: 2px;
	bottom: 0;
	left: 15px;
	border-radius: 2px;
	background: var(--light);
}

.progress {
	height: 5px;
}

.progress .progress-bar {
	width: 0px;
	transition: 2s;
}

.service-item {
	padding: 30px;
	text-align: center;
	background: var(--secondary);
}

.service-item i {
	width: 75px;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--light);
	color: var(--primary);
	transition: 0.5s;
}

.service-item:hover i {
	background: var(--primary);
	color: var(--light);
}

.portfolio-item .portfolio-btn {
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	font-size: 90px;
	background: rgba(44, 62, 80, 0.9);
	opacity: 0;
	transition: 0.5s;
}

.portfolio-item:hover .portfolio-btn {
	opacity: 1;
	width: calc(100% - 60px);
	height: calc(100% - 60px);
	top: 30px;
	left: 30px;
}

.portfolio-item i {
	opacity: 0;
	transition: 0.3s;
	transition-delay: 0.3s;
}

.portfolio-item:hover i {
	opacity: 1;
}

.testimonial-carousel .owl-dots {
	margin-top: 25px;
	text-align: center;
}

.testimonial-carousel .owl-dot {
	display: inline-block;
	margin: 0 5px;
	width: 15px;
	height: 15px;
	background: var(--light);
}

.testimonial-carousel .owl-dot.active {
	background: var(--primary);
}

.text-justify {
	text-align: justify !important;
}
